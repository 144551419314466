import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ClientsService } from "app/apiServices/clients.service";
import { InventoryService } from "app/apiServices/inventory.service";
import { UserServicesService } from "app/apiServices/user-services.service";
import { FormValidatorService } from "app/core/service/form-validator.service";
import { InUserRes } from "app/interfaces/user.interface";
import { ComissionProduct } from "app/models/comssionProduct";
import { User } from "app/models/user";

@Component({
  selector: "app-form",
  templateUrl: "./form.component.html",
  styleUrls: ["./form.component.css"],
})
export class FormComponent implements OnInit {
  form: FormGroup;
  dataSource: ComissionProduct;
  edit = false;
  dialogTitle: string;

  vendors: User[] = [];

  validationMessages = {
    product_id: [{ type: "required", message: "" }],
    client_id: [{ type: "required", message: "" }],
    cost_per_liter: [{ type: "required", message: "" }],
    user_id: [{ type: "required", message: "Elige un vendedor" }],
  };

  clients: any[] = [];
  products: any[];
  selectedProducts = [];
  dropdownSettings = {};
  dropdownProductsSettings: any = {};

  constructor(
    private dialogRef: MatDialogRef<FormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    public formService: FormValidatorService,
    public userService: UserServicesService,
    public clientService: ClientsService,
    public productSerice: InventoryService
  ) {
    this.data = data;
    this.dropdownSettings = {
      singleSelection: data.action === "edit" ? true : false,
      idField: "id",
      textField: "name",
      noDataAvailablePlaceholderText: "No hay datos",
      selectAllText: "Seleccionar Todas",
      unSelectAllText: "Deseleccionar Todas",
      searchPlaceholderText: "Buscar",
      itemsShowLimit: 6,
      allowSearchFilter: true,
    };
    this.dropdownProductsSettings = {
      ...this.dropdownSettings,
    };

    if (data.action === "edit") {
      this.dialogTitle = "Editar Registro";
      this.dataSource = data.data;
      this.edit = true;
      this.selectedProducts = [data.data.product];
    } else {
      this.dialogTitle = "Agregar Registro";
      this.dataSource = {};
    }
  }

  ngOnInit(): void {
    this.getData();
    this.getUsers();
    this.form = this.createForm();
  }

  getData() {
    this.productSerice.getProducts().subscribe((r) => {
      this.products = r.data.filter((p) => p.active);
    });

    this.clientService.readCompanies().subscribe((r) => {
      r.data.forEach((element) => {
        if (element.clients.length >= 1 && element.active)
          this.clients.push(element);
      });
    });
  }

  createForm(): FormGroup {
    if (this.edit) {
      return this.fb.group({
        id: [this.dataSource.id],
        product_id: [this.dataSource.product.id, [Validators.required]],
        client_id: [this.dataSource.client.id, [Validators.required]],
        cost_per_liter: [
          this.leadingZero(this.dataSource.cost_per_liter.toString()),
          [Validators.required],
        ],
        user_id: [this.dataSource.user.id, []],
      });
    }
    return this.fb.group({
      product_id: [undefined, []],
      client_id: [undefined, []],
      cost_per_liter: [undefined, [Validators.required]],
      user_id: [undefined, [Validators.required]],
    });
  }

  save(): void {
    if (this.form.valid) {
      this.dataSource = this.form.value;
      this.dataSource.cost_per_liter = Number(
        parseFloat(this.dataSource.cost_per_liter.toString())
      );
      this.storeUpdate(this.edit ? "update" : "store");
    } else {
      this.formService.allFields(this.form);
      alert("Datos incorrectos");
    }
  }

  storeUpdate(method: string): void {
    if (method === "store") {
      const comissionProducts = {
        commision_products: this.form.value.product_id.map((product) => {
          return {
            client_id: this.form.value.client_id,
            product_id: product.id,
            cost_per_liter: this.form.value.cost_per_liter,
            user_id: this.form.value.user_id,
          };
        }),
      };

      this.clientService.createCommision(comissionProducts).subscribe((r) => {
        this.closeModal();
      });
    } else {
      this.dataSource.product_id = this.form.value.product_id[0]?.id ?? null;
      this.clientService.updateCommision(this.dataSource).subscribe((r) => {
        this.closeModal();
      });
    }
  }

  onLeadingZero(event: Event): void {
    const num = (event.target as HTMLInputElement).value
      .replace("$", "")
      .replace(",", "")
      .replace(" ", "");
    const dec = num.split(".")[1];
    const len = dec && dec.length > 2 ? dec.length : 2;
    (event.target as HTMLInputElement).value = `$ ${Number(num).toFixed(len)}`;
  }

  leadingZero(num: string): string {
    const dec = num.split(".")[1];

    if (!dec && num == "0") return "0.00";
    else if (!dec && num != "0") return `${num}.00`;

    const len = dec && dec.length > 2 ? dec.length : 2;
    const fixed = Number(num).toFixed(len);
    return fixed;
  }

  getUsers(): void {
    this.userService.readUsers().subscribe((res: InUserRes) => {
      if (res.status === "Éxito") {
        const users = res.data;
        this.vendors = users.filter((user) => {
          if (user.roles && user.active) {
            return user.roles[0].id === 4 || user.roles[0].id === 20;
          }
        });
      }
    });
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
