<div class="">
    <div class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-slate-200 border-0">
        <div class="grid grid-cols-2 gap-2 rounded-t mb-0 px-4 py-3 border-0 pt-6">
            <div class="basis-1/2 flex justify-start">
                <!-- <span
                    class="z-10 h-full leading-snug font-normal absolute text-center text-slate-300 bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3">
                    <i class="fa fa-search"></i>
                </span>
                <input type="text"
                    class="w-48 pl-12 py-3 rounded-full border text-sm border-gray-300 focus:outline-none focus:border-blue-400"
                    (keyup)="updateFilter($event)" placeholder="Buscar" /> -->
            </div>

            <div class="flex justify-end">
                <!-- <div class="w-full pr-3">
                    <select #statusId
                        class="border-1 px-3 py-3 placeholder-slate-300 text-slate-600 bg-white rounded border-gray-300 text-base focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        (change)="onChangeStatus($event.target.value)">
                        <option value="-1">Selecciona un estatus</option>
                        <option value="0">TERMINADAS</option>
                        <option *ngFor="let state of status" [value]="state.id">
                            {{ state.tmpName }}
                        </option>
                    </select>
                </div> -->
                <div class="px-2 w-80">
                    <input type="text" #folio
                        class="bg-gray-50 border border-gray-300 text-gray-900 placeholder-current text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5"
                        placeholder="Buscar" />
                </div>
                <!-- <div class="flex justify-center items-center mx-3">
                    <i class="fas fa-cloud-download-alt cursor-pointer"></i>
                </div> -->
                <button (click)="sender()"
                    class="bg-sky-600 text-white active:bg-sky-600 font-bold uppercase text-sm px-6 py-3 rounded-full shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button">
                    <i class="fas fa-cloud-download-alt cursor-pointer"></i>
                </button>
            </div>

            <div class="basis-1/2 flex justify-end"></div>
        </div>

        <div class="grid">
            <div class="block w-full overflow-x-auto rounded-lg">
                <ngx-datatable #table class="material" [columnMode]="ColumnMode.force" [headerHeight]="50"
                    [footerHeight]="50" rowHeight="auto" [limit]="10" [rows]="temp" [loadingIndicator]="loading"
                    [scrollbarH]="true" (sort)="onSort($event)">
                    <ngx-datatable-column name="Folio">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            {{ row.folio }}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="C. Vendedora">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            {{ row?.seller?.legal_name }}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Tipo de venta">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            {{ row.type_code == "DIR" ? "Directa" : "Normal" }}
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="Vendedor">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            {{ row?.vendor?.name }} {{ row?.vendor?.last_name }}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Producto">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            {{ row?.product?.name }}
                        </ng-template>
                    </ngx-datatable-column>
                    <ngx-datatable-column name="Estatus">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            {{
                            row?.order_status?.id == 2
                            ? "Prioridad de carga"
                            : row?.order_status?.name
                            }}
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="Creado">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            {{ row.created_at }}
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="Cliente">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            {{ row?.client?.company?.legal_name }}
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="Transportista">
                        <ng-template let-row="row" ngx-datatable-cell-template>
                            {{
                            row.check_own_transportist
                            ? row.own_transportist_name
                            : row?.transportist?.company?.legal_name
                            }}
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-column name="Opciones" [sortable]="false">
                        <ng-template #buttonsTemplate let-row="row" let-value="value" ngx-datatable-cell-template>
                            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Menu">
                                <i class="fas fa-ellipsis-v"></i>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item (click)="openDialog(row)">
                                    <i class="fas fa-eye mx-2 p-2"></i>
                                    <span>Ver Documentos</span>
                                </button>

                                <!-- <button mat-menu-item (click)="openDetailDialog(row)">
                                    <i class="fas fa-info-circle mx-2"></i>
                                    <span>Detalle</span>
                                </button>
                                <button mat-menu-item (click)="addOrderLab(row)" *ngIf="row.order_status.id >= 2">
                                    <i class="fas fa-file-alt"></i>
                                    <span>{{
                                        row.order_status.id > 2 || row?.lab_orders[0]?.approved
                                        ? "Ver Orden de Producción"
                                        : "Orden de Producción"
                                        }}
                                    </span>
                                </button>

                                <button mat-menu-item (click)="openOrderEvidencesDialog(row)"
                                    *ngIf="row?.order_attachments?.length > 0">
                                    <i class="fas fa-file-archive mx-2"></i>
                                    <span>Ver evidencias </span>
                                </button>

                                <button mat-menu-item *ngIf="row?.order_status?.id <= 3 && isAuthorizedUser"
                                    (click)="confirmDelete(row.id)">
                                    <i class="fas fa-trash-alt mx-2"> </i>
                                    <span>Eliminar</span>
                                </button> -->
                            </mat-menu>
                        </ng-template>
                    </ngx-datatable-column>

                    <ngx-datatable-footer>
                        <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
                            let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset"
                            let-isVisible="isVisible">
                            <div class="page-count hide">{{ rowCount }} total</div>

                            <div>
                                <form [formGroup]="form">
                                    <div>
                                        <div class="flex justify-content-between align-items-center">
                                            <label class="flex align-items-center mx-4 my-2" for="rowsPerPage">Mostrar
                                                por página:
                                            </label>

                                            <select class="rounded form-control mx" formControlName="rowsPerPage"
                                                (change)="onLimitChange($event)" id="rowsPerPage" name="rowsPerPage">
                                                <option [value]="5">5</option>
                                                <option [value]="10">10</option>
                                                <option [value]="25">25</option>
                                                <option [value]="50">50</option>
                                            </select>
                                        </div>
                                    </div>
                                </form>
                            </div>

                            <datatable-pager [pagerLeftArrowIcon]="'datatable-icon-left'"
                                [pagerRightArrowIcon]="'datatable-icon-right'"
                                [pagerPreviousIcon]="'datatable-icon-prev'" [pagerNextIcon]="'datatable-icon-skip'"
                                [page]="curPage" [size]="pageSize" [count]="rowCount"
                                (change)="table.onFooterPage($event)">
                            </datatable-pager>
                        </ng-template>
                    </ngx-datatable-footer>
                </ngx-datatable>
            </div>
        </div>
    </div>
</div>