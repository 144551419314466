import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Route } from "app/models/route";
import { ColumnMode } from '@swimlane/ngx-datatable';
import { ClientsService } from 'app/apiServices/clients.service';
import { InventoryService } from 'app/apiServices/inventory.service';
import { SalesService } from 'app/apiServices/sales.service';
import { UserServicesService } from 'app/apiServices/user-services.service';
import { AlertComponent } from 'app/components/alerts/alert/alert.component';
import { NotificationsComponent } from 'app/components/alerts/notifications/notifications.component';
import { FormValidatorService } from 'app/core/service/form-validator.service';
import { InCompaniesRes } from 'app/interfaces/company.interface';
import { InOrderStatusRes } from 'app/interfaces/order.status';
import { InPaymentMethodRes } from 'app/interfaces/payment.method';
import { InProductRes } from 'app/interfaces/product.interface';
import { InUserRes } from 'app/interfaces/user.interface';
import { Address, Shipping } from 'app/models/address';
import { Company } from 'app/models/company';
import { DiscountProduct } from 'app/models/discountProduct';
import { OrderStatus } from 'app/models/orderStatus';
import { PaymentMethod } from 'app/models/paymentMethod';
import { Product } from 'app/models/product';
import { RoutePriceTransportist } from 'app/models/routePriceTransportist';
import { Sale } from 'app/models/sale';
import { User } from 'app/models/user';
import { DataService } from 'app/services/data.service';
import { formatDate } from '@angular/common';
import { Select, initTE } from "tw-elements";


@Component({
  selector: 'app-form',
  templateUrl: './formTry.component.html',
  styleUrls: ['./form.component.css', "../../../../../../src/styles.css"]
})
export class FormComponent implements OnInit {
  @ViewChild("supplier_id", { static: true })
  supplierSelect: ElementRef;
  @ViewChild("buyer_id", { static: true })
  buyerSelect: ElementRef;

  @ViewChild("product_id", { static: true })
  productSelect: ElementRef;

  @ViewChild("service_id", { static: true })
  serviceSelect: ElementRef;

  ColumnMode = ColumnMode;
  main: boolean = null;
  //#region Options
  selectOptions = {
    selectFilter: true,
    selectNoResultText: "No se encontraron resultados",
    selectSearchPlaceholder: "Buscar...",
  };
  selectCommonClasses = {
    optionsList: "list-none m-0 p-0 w-inherit",
    selectInput:
      "peer block min-h-[auto] w-full rounded border-0 bg-transparent outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none dark:text-gray-200 dark:placeholder:text-gray-200 [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0 cursor-pointer data-[te-input-disabled]:bg-[#e9ecef] data-[te-input-disabled]:cursor-default group-data-[te-was-validated]/validation:mb-4 dark:data-[te-input-disabled]:bg-zinc-600 pr-8",
  };
  //#endregion
  //#region Variables
  dialogTitle: string = "Nueva Compra";
  detailTitle: string = "Detalles de Compra:"

  dataSource: any;
  edit = false;

  mains: any;

  form: FormGroup;
  detailsPurchaseForm: FormGroup;
  totalForm: FormGroup;

  companies: Company[] = [];
  mainCompanies: Company[] = []
  sellers: Company[] = [];
  clients: Company[] = [];
  transportists: Company[] = [];
  users: User[] = [];
  paymentMethods: PaymentMethod[] = [];
  products: Product[];
  filteredProducts: Product[];
  routes: Route[] = [];
  statuses: OrderStatus[] = [];
  priceRoutesTransportist: RoutePriceTransportist[] = [];
  discountProducts: DiscountProduct[] = [];

  mainAddress: Shipping;
  currentTransportistId: number = null;
  currentTax: string = "iva";
  hasExternalComission: boolean;
  currentUserIsAVendor: boolean = false;
  //#endregion

  //#region Provider Select Search Component
  dropdownSettingsSeller: any = {};
  dropdownSettingsBuyer: any = {};
  //#endregion
  buyers: any[] = []
  suppliers: any[] = []
  services: any[] = []

  disableProvider: boolean = false;

  detallesCompra: FormGroup[] = []
  user: User

  onProviderSelect(item: any) { }

  subOrder: boolean = false

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<FormComponent>,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private _snack: MatSnackBar,
    private dataService: DataService,
    private companiesService: ClientsService,
    private inventoryService: InventoryService,
    private userService: UserServicesService,
    private salesService: SalesService,
    public formService: FormValidatorService,
    private apiService: UserServicesService
  ) {
    data.action === "store" ? this.main = true : this.main = false

    if (data.action === "sub_oc") {
      this.main = true
      this.subOrder = true

      this.dialogTitle = "Nueva Compra Relacionada"
      this.detailTitle = " Agregar Servicios Relacionados:"
    }

    if (data.action == "edit") this.subOrder = false
    if (data.action == "sub_oc") this.subOrder = true
    if (data.action == "store") this.subOrder = false
    if (data.action == "edit_subOC") this.subOrder = true
    //#region DropDown Settings
    this.dropdownSettingsSeller = {
      singleSelection: true,
      idField: "id",
      textField: "legal_name",
      noDataAvailablePlaceholderText: "No hay datos",
      selectAllText: "Seleccionar Todas",
      unSelectAllText: "Deseleccionar Todas",
      itemsShowLimit: 4,
      allowSearchFilter: true,
    };

    this.dropdownSettingsSeller = { ...this.dropdownSettingsSeller };
    this.dropdownSettingsSeller.singleSelection = true;
    this.dropdownSettingsSeller.itemsShowLimit = 1;


    this.dropdownSettingsBuyer = {
      singleSelection: true,
      idField: "id",
      textField: "name",
      noDataAvailablePlaceholderText: "No hay datos",
      selectAllText: "Seleccionar Todas",
      unSelectAllText: "Deseleccionar Todas",
      itemsShowLimit: 4,
      allowSearchFilter: true,
    };

    this.dropdownSettingsBuyer = { ...this.dropdownSettingsBuyer };
    this.dropdownSettingsBuyer.singleSelection = true;
    this.dropdownSettingsBuyer.itemsShowLimit = 1;
    //#endregion

    if (data.action === "edit") {
      this.dialogTitle = "Editar Compra";
      this.dataSource = {
        ...data.data,
      };
      this.iva = true
      this.buyers = [this.dataSource.buyer]
      this.suppliers = [this.dataSource.supplier]

      const purchaseOrderDeails: any[] = this.dataSource.purchase_order_details
      purchaseOrderDeails.forEach((element) => {
        let action
        element.product ? action = "product" : action = "service"

        let form: FormGroup = this.createFormPurchaseEdit(action);
        form.controls.purchase_order_detail_id.patchValue(element.id)

        if (element.product) form.controls.product_id.patchValue(element.product.id)
        if (element.service) form.controls.service_id.patchValue(element.service.id)
        element.product ? form.controls.action.patchValue(true) : form.controls.action.patchValue(false)

        form.controls.clave_sat.patchValue(element.clave_sat)
        form.controls.unit_key.patchValue(element.unit_key)
        form.controls.quantity.patchValue(element.quantity)
        form.controls.agreed_prize.patchValue(element.agreed_prize)
        form.controls.amount.patchValue(element.amount)
        form.controls.discount_1.patchValue(element.discount_1)

        form.controls.subtotal.patchValue(element.subtotal)
        form.controls.total.patchValue(element.total)
        form.controls.observations.patchValue(element.observations)

        form.controls.iva.patchValue((element.iva * 100) + "%")

        if (action == "product") {
          if (element.product.ieps) {
            form.controls.ieps.patchValue(true)
            var ieps_cost = element.product.ieps_cost * element.quantity
            form.addControl('ieps_cost', new FormControl(ieps_cost.toFixed(6), [Validators.required]))
          }
        }

        this.detallesCompra.push(form)
      })

      this.edit = true;
      this.hasExternalComission = this.dataSource.type_code == "NOR";

    } else if (data.action === "edit_subOC") {
      this.dialogTitle = "Editar Sub Orden de Compra";
      this.dataSource = {
        ...data.data,
      };

      const purchaseOrderDeails: any[] = this.dataSource.purchase_order_details
      purchaseOrderDeails.forEach((element) => {
        let action
        element.product ? action = "product" : action = "service"

        let form: FormGroup = this.createFormPurchaseEdit(action);
        form.controls.purchase_order_detail_id.patchValue(element.id)

        form.controls.service_id.patchValue(element.service.id)
        element.product ? form.controls.action.patchValue(true) : form.controls.action.patchValue(false)

        form.controls.clave_sat.patchValue(element.clave_sat)
        form.controls.unit_key.patchValue(element.unit_key)
        form.controls.quantity.patchValue(element.quantity)
        form.controls.agreed_prize.patchValue(element.agreed_prize)
        form.controls.amount.patchValue(element.amount)
        form.controls.discount_1.patchValue(element.discount_1)

        form.controls.subtotal.patchValue(element.subtotal)
        form.controls.total.patchValue(element.total)
        form.controls.observations.patchValue(element.observations)

        form.controls.iva.patchValue(element.iva + "%")
        form.addControl('retention_iva', new FormControl(0.0, [Validators.required]))

        form.controls.retention_iva.patchValue(element.retention_iva + "%")
        this.detallesCompra.push(form)
      })
      this.edit = true;
    } else {
      this.dialogTitle = "Nueva Compra";
      this.dataSource = new Sale({});
      this.hasExternalComission = true;
    }

    this.currentUserIsAVendor =
      this.dataService.userValue.roles &&
      this.dataService.userValue.roles[0].id == 4;
  }

  ngOnInit(): void {
    if (this.data.action === "sub_oc") {
      this.dialogTitle = "Nueva Compra Relacionada"
    }

    if (this.data.action === "edit_subOC") {
      this.dialogTitle = "Editar Compra Relacionada"
    }

    this.form = this.createForm()
    this.totalForm = this.createFinalForm()

    this.getUsers();
    this.getCompanies();
    this.getPaymentMethods();
    this.getOrderStatuses();
    this.getProducts();
    this.getServices();

    this.createSelects();
  }

  createSelects(): void {
    initTE({ Select })
    new Select(this.supplierSelect.nativeElement, this.selectOptions)
    new Select(this.buyerSelect.nativeElement, this.selectOptions)
  }

  createSelectsPurchase(): void {
    initTE({ Select })
    new Select(this.productSelect.nativeElement, this.selectOptions)
    new Select(this.serviceSelect.nativeElement, this.selectOptions)
  }

  getCompanies(): void {
    this.companiesService.readCompanies().subscribe((res: InCompaniesRes) => {
      if (res.status === "Éxito") {
        this.companies = res.data.filter((company) => {
          return company.active;
        });

        this.mains = res.data.filter((company) => {
          return company.active && company.main
        })


        this.sellers = this.companies.filter((company) => {
          return company.active && company.main !== true && company.suppliers.length !== 0 || company.transportists.length !== 0;
        });

        this.transportists = this.companies.filter((company) => {
          if (company.active && company.transportists.length > 0 && company.id !== 3) {
            return true;
          }
          return false;
        });
      }
    });
  }

  getUsers(): void {
    this.apiService.getMyself().subscribe({
      next: (r) => {
        this.user = r.data
        if (this.user.roles[0].id === 1 || this.user.roles[0].id === 2) {
          this.userService.readUsers().subscribe((res: InUserRes) => {
            if (res.status === "Éxito") {
              this.users = res.data.filter((user) => {
                return user.roles[0].id === 13 || user.roles[0].id === 14
              })
            }
          });
        } else if (this.user.roles[0].id === 13 || this.user.roles[0].id === 14) {
          this.users = [this.user]
        }

      }, error: (e) => {
      }
    })
  }

  getProducts(): void {
    this.inventoryService.getProducts().subscribe((res: InProductRes) => {
      if (res.status === "Éxito") {
        this.products = res.data.filter((product) => product.active);
      }
    });
  }

  getServices(): void {
    this.salesService.getServices().subscribe({
      next: (r) => {
        this.services = r.data
      }, error: (e) => {

      }
    })
  }

  //#region FirstForm
  validationMessagesFirstPart = {
    main_company_id: [{ type: "required", message: "Campo requerido" }],
    folio: [{ type: "required", message: "Campo requerido" }],
    load_date: [{ type: "required", message: "Campo requerido" }],
    end_date: [{ type: "required", message: "Campo requerido" }],
    payment_method_id: [{ type: "required", message: "Campo requerido" }],
    req_number: [{ type: "required", message: "Campo requerido" }],
    supplier_id: [{ type: "required", message: "Campo requerido" }],
    buyer_id: [{ type: "required", message: "Campo requerido" }],
    order_status_id: [{ type: "required", message: "Campo requerido" }],
  }

  createForm(): FormGroup {
    return this.fb.group({
      main_company_id: [this.main ? null : this.dataSource.main_company?.id, [Validators.required]],
      folio: [this.main ? null : this.dataSource.folio, [Validators.required]],
      load_date: [this.main ? null : this.dataSource.load_date, [Validators.required]],
      end_date: [this.main ? null : this.dataSource.end_date, [Validators.required]],
      payment_method_id: [this.main ? null : this.dataSource.payment_method.id, [Validators.required]],
      req_number: [this.main ? null : this.dataSource.req_number, []],
      supplier_id: [this.main ? null : this.dataSource.supplier?.id, [Validators.required]],
      buyer_id: [this.main ? null : this.dataSource.buyer?.id, [Validators.required]],
      order_status_id: [this.main ? 1 : this.dataSource.order_status.id, [Validators.required]]
    })
  }
  //#endregion

  //#region PurchaseForm
  validationMessagesProductService = {
    product_id: [{ type: "required", message: "Campo Requerido" }],
    service_id: [{ type: "required", message: "Campo requerido" }],

    clave_sat: [{ type: "required", message: "Campo requerido" }],
    unit_key: [{ type: "required", message: "Campo requerido" }],
    quantity: [{ type: "required", message: "Introduzca la cantidad" }],

    discount_1: [{ type: "required", message: "Campo requerido" }],

    subtotal: [{ type: "required", message: "Campo requerido" }],
    total: [{ type: "required", message: "Campo requerido" }],

    agreed_prize: [{ type: "required", message: "Campo requerido" }],
    amount: [{ type: "required", message: "Campo requerido" }],

    observations: [{ type: "required", message: "Agrege las notas correspondientes" }],

    iva: [{ type: "required", message: "Campo Requerido" }],
    retention_iva: [{ type: "required", message: "Campo Requerido" }],
    ieps_cost: [{ type: "required", message: "Campo Requerido" }]
  }

  iva: boolean = false
  product: boolean = false

  createFormPurchase(action: string) {
    var detailsPurchase: FormGroup;
    detailsPurchase = this.fb.group({
      product_id: [action == "product" ? null : undefined, []],
      service_id: [action == "service" ? null : undefined, []],

      clave_sat: [, [Validators.required]],
      unit_key: [, [Validators.required]],
      quantity: [0, [Validators.required]],

      discount_1: [0.0, [Validators.required]],

      ieps: [false, []],
      agreed_prize: [0.0, [Validators.required]],
      amount: [{ value: 0.0, }, [Validators.required]],

      subtotal: [{ value: 0.0, }, [Validators.required]],
      total: [{ value: 0.0, }, [Validators.required]],

      observations: [, []]
    })

    if (action == "product") {
      this.iva = true
      this.product = true
      detailsPurchase.addControl('iva', new FormControl("16%", [Validators.required]))
    } else if (action == "service") {

      detailsPurchase.addControl('iva', new FormControl("16%", [Validators.required]))
      detailsPurchase.addControl('retention_iva', new FormControl(0.0, [Validators.required]))
    }

    this.detallesCompra.push(detailsPurchase)
  }

  createFormPurchaseEdit(action: string) {
    return this.fb.group({
      purchase_order_detail_id: [, []],
      product_id: [action == "product" ? null : undefined, []],
      service_id: [action == "service" ? null : undefined, []],

      clave_sat: [, [Validators.required]],
      unit_key: [, [Validators.required]],
      quantity: [0, [Validators.required]],

      discount_1: [0.0, [Validators.required]],

      ieps: [false, []],
      agreed_prize: [0.0, [Validators.required]],
      amount: [{ value: 0.0, }, [Validators.required]],

      iva: [0.0, [Validators.required]],

      subtotal: [{ value: 0.0, }, [Validators.required]],
      total: [{ value: 0.0, }, [Validators.required]],

      observations: [, []],
      action: [action === "product" ? true : false, []]
    });
  }

  readValors() {
    /* Creates the resume of purchase */
    var amount: number = 0.0
    var total_discount: number = 0.0
    var subtotal: number = 0.0
    var total: number = 0.0
    var ret_iva: number = 0.0
    var ieps_total: number = 0.0

    var precio: number = 0.0
    var cantidad: number = 0

    if (!this.subOrder) {
      /* Total applicable to the sum of al registers */
      this.detallesCompra.forEach((form) => {
        /* Form */
        // console.log("Productos Compra", form.controls)

        /* Calculo Total IEPS */
        var ieps = 0.0
        if (form.controls.ieps.value) {
          ieps = +form.controls.ieps_cost?.value
        } else {
          ieps = 0.0
        }
        ieps_total = ieps + ieps_total

        // console.log("Ieps Controls", ieps_total)

        /* Calculo importe */
        var _amount = +form.controls.amount.value
        amount = amount + _amount

        /* Calculo descuento Total */
        var _total_disctount = +form.controls.discount_1.value * +form.controls.quantity.value
        total_discount = total_discount + _total_disctount

        /* Calculo subtotal */
        var _subtotal = +form.controls.subtotal.value
        subtotal = (subtotal + _subtotal)

        /* Calculo Total */
        var _total = +form.controls.subtotal.value
        total = total + _total

        /* Calculo cantidad total */
        var _cantidad = +form.controls.quantity.value
        cantidad = cantidad + _cantidad

        /* Calculo precio total por litro */
        var _precioTotal = +form.controls.agreed_prize.value
        precio = (precio + _precioTotal) / cantidad
      })



      this.totalForm.controls.amount.patchValue(amount.toFixed(6))
      this.totalForm.controls.discount.patchValue(total_discount.toFixed(6))
      this.totalForm.controls.subtotal.patchValue(subtotal.toFixed(6))
      this.totalForm.controls.ieps.patchValue(ieps_total.toFixed(6))
      /* Nuevos Campos de Compra Principal */
      this.totalForm.controls.precio.patchValue(precio.toFixed(6))
      this.totalForm.controls.litros.patchValue(cantidad)
    } else {
      this.detallesCompra.forEach((form) => {
        var _amount = +form.controls.amount.value
        amount = amount + _amount

        /* Calculo descuento Total */
        var _total_disctount = +form.controls.discount_1.value * +form.controls.quantity.value
        total_discount = total_discount + _total_disctount

        /* Calculo subtotal */
        var _subtotal = +form.controls.subtotal.value
        subtotal = (subtotal + _subtotal) - ieps_total

        /* Retencion Iva Total */
        //  console.log("Ret Iva normal", this.getIvaValue(form.controls.retention_iva.value)/100)
        ret_iva = (this.getIvaValue(form.controls.retention_iva.value) / 100)

        /* Calculo Total */
        var _total = +form.controls.subtotal.value + ieps_total
        total = _total
      })

      this.totalForm.controls.amount.patchValue(amount.toFixed(6))
      this.totalForm.controls.discount.patchValue(total_discount.toFixed(6))
      this.totalForm.controls.subtotal.patchValue(subtotal.toFixed(6))
      this.totalForm.controls.total.patchValue(total.toFixed(6))
    }

    var ivaTotal: number = 0.0
    var ivaTo: any = 0.0
    this.detallesCompra.forEach((detail) => {
      ivaTo = this.getIvaValue(detail.controls.iva.value) / 100
      // console.log("IvaTo", ivaTo)
      // let value = detail.controls.subtotal.value
      // let ivaApplied = ivaTo * value
      // ivaTotal = ivaTotal + ivaApplied
    })

    ivaTotal = +this.totalForm.controls.subtotal.value * ivaTo
    // console.log("subototal", ivaTotal)

    if (!this.subOrder) {
      total = total + ivaTotal + ieps_total
      this.totalForm.controls.total.patchValue(total.toFixed(6))
    } else {
      this.totalForm.controls.retention_iva.patchValue(((total + ivaTotal) * ret_iva).toFixed(6))

      // console.log("Total no mod", total)
      // console.log("Iva Total", ivaTotal)
      // console.log("Ret Iva", ret_iva)

      total = (total + ivaTotal + ieps_total) - ((total + ivaTotal) * ret_iva)

      // console.log("Total modificado", total)
      this.totalForm.controls.total.patchValue(total.toFixed(6))
    }

    this.totalForm.controls.iva.patchValue(ivaTotal.toFixed(6))
  }
  //#endregion

  //#region SecondForm
  validationMessagesLastPart = {
    product_specifications: [{ type: "required", message: "Necesitamos observaciones o notas" }],

    amount: [{ type: "required", message: "Campo requerido" }],
    discount: [{ type: "required", message: "Campo requerido" }],
    subtotal: [{ type: "required", message: "Campo requerido" }],
    ieps: [{ type: "required", message: "Campo requerido" }],
    iva: [{ type: "required", message: "Campo requerido" }],

    retention_isr: [{ type: "required", message: "Campo requerido" }],
    retention_iva: [{ type: "required", message: "Campo requerido" }],

    total: [{ type: "required", message: "Campo requerido" }],
  }
  createFinalForm(): FormGroup {
    return this.fb.group({
      product_specifications: [this.main ? null : this.dataSource.product_specifications, [Validators.required]],
      amount: [this.main ? 0.0 : this.dataSource.amount, [Validators.required]],
      discount: [this.main ? 0.0 : this.dataSource.discount, [Validators.required]],
      subtotal: [this.main ? 0.0 : this.dataSource.subtotal, [Validators.required]],
      ieps: [this.main ? 0.0 : this.dataSource.ieps, [Validators.required]],
      iva: [this.main ? 0.0 : this.dataSource.iva, [Validators.required]],
      retention_isr: [this.main ? 0.0 : this.dataSource.retention_isr, [Validators.required]],
      retention_iva: [this.main ? 0.0 : this.dataSource.retention_iva, [Validators.required]],
      total: [this.main ? 0.0 : this.dataSource.total, [Validators.required]],

      precio: [0.0, []],
      litros: [0.0, []]
    })
  }
  //#endregion

  //#region Calculo de compra INDIVIDUAL
  onIntroduceIVA(value: any, row: any) {
    let iva = this.getIvaValue(row.controls.iva.value) / 100
    let ret_iva0 = this.getIvaValue(row.controls.retention_iva?.value ?? 0) / 100

    let quantity = +row.controls.quantity.value
    let supply_price = row.controls.agreed_prize.value
    let discount = (+row.controls.discount_1.value) * quantity
    let ieps = 0.0

    if (row.controls.ieps.value) {
      // console.log("Enters Normally")
      let selectedProduct = this.products.filter((product) => {
        return product.id == +row.controls.product_id.value
      })

      var ieps_cost = selectedProduct[0].ieps_cost * row.controls.quantity.value
      ieps = ieps_cost
      row.controls.ieps_cost.patchValue(ieps_cost.toFixed(6))
    }

    /* Importe = amount */
    let amount_ = quantity * supply_price
    row.controls.amount.patchValue(amount_.toFixed(6))

    let subtotal = amount_ - discount
    row.controls.subtotal.patchValue(subtotal.toFixed(6))

    let total_iva = subtotal * iva

    let re_iva = (subtotal + total_iva) * ret_iva0

    // console.log("IEPS", ieps)
    let totalRet = subtotal + ieps + total_iva - re_iva

    row.controls.total.patchValue(totalRet.toFixed(6))
    row.controls.iva.patchValue(row.controls.iva.value)
  }


  onSelectProduct(product_id: any, row: any): void {
    let selectedProduct = this.products.filter((product) => {
      return product.id == product_id
    })

    let supply_price = selectedProduct[0].supply_price
    row.controls.agreed_prize.patchValue(supply_price)

    row.controls.clave_sat.patchValue(selectedProduct[0].clave_sat)

    if (selectedProduct[0].ieps) {
      row.controls.ieps.value = true

      var ieps_cost = selectedProduct[0].ieps_cost * row.controls.quantity.value
      row.addControl('ieps_cost', new FormControl(ieps_cost.toFixed(6), [Validators.required]))
    } else if (row.controls.ieps_cost) {
      row.controls.ieps.value = false
      row.controls.ieps_cost.patchValue(0)
    }
  }

  onSelectService(service_id: any, row: any) {
    let selectedService = this.services.filter((service) => {
      return service.id == service_id
    })

    row.controls.retention_iva.patchValue(selectedService[0].iva_retain + "%")
    row.controls.iva.patchValue(selectedService[0].iva + "%")
  }


  getNumberValue(text: any): number {
    let match = text.match(/\d{1,3}(,\d{3})*(\.\d+)?/)
    return parseFloat(match[0].replace(/,/g, ''))
  }

  getIvaValue(text: any): number {
    let texto = text.toString()

    let regex: RegExp = /\d+(\.\d+)?/;
    return parseFloat(texto.match(regex)[0])
  }

  //#endregion

  getPaymentMethods(): void {
    this.salesService.getPaymentMethods().subscribe((res: InPaymentMethodRes) => {
      if (res.status === "Éxito") {
        this.paymentMethods = res.data.filter((paymentMethod) => !paymentMethod.type_invoice);
      }
    });
  }

  getDiscountsProducts(): void {
    this.companiesService.readDiscountsProduct().subscribe((res) => {
      if (res.status === "Éxito") {
        this.discountProducts = res.data;
      }
    });
  }

  getOrderStatuses(): void {
    if (!this.main) {
      this.salesService.getSalesTypes().subscribe((res: InOrderStatusRes) => {
        if (res.status === "Éxito") {
          this.statuses = res.data;
        }
      });
    }
  }

  filterProducts(): void {
    if (!this.form.controls.check_own_transportist.value) {
      const routesPriceTransportist = this.priceRoutesTransportist.filter(
        (route) => {
          return route.transportist.id == this.currentTransportistId;
        }
      );
      const routesTransportist = this.routes.filter((route) => {
        return (
          routesPriceTransportist.find((routePrice) => {
            return routePrice.route.id == route.id;
          }) &&
          this.mainAddress.address.locality.municipality.id ==
          route.to_locality.municipality.id
        );
      });

      this.filteredProducts = routesPriceTransportist
        .filter((routeTransportist) => {
          return routesTransportist.find((route) => {
            return route.id == routeTransportist.route.id;
          });
        })
        .map((routeTransportist) => {
          return routeTransportist.product;
        });
    } else {
      this.filteredProducts = this.products.slice();
    }
  }

  showValidationModal(
    message: string = "Debe completar todos los campos."
  ): void {
    this.dialog.open(AlertComponent, {
      data: {
        body: message,
      },
    });
  }

  convertToNumber(value: string): number {
    let converted: number;
    const val = String(value).replace(",", "");
    converted = Number(parseFloat(val));

    return converted;
  }

  closeModal(): void {
    this.dialogRef.close(true);
  }

  openSnack(color: string, message: string): void {
    this._snack.openFromComponent(NotificationsComponent, {
      panelClass: [color],
      data: { message },
      duration: 3000,
      verticalPosition: "top",
    });
  }

  deleteform(row: any) {
    const index = this.detallesCompra.indexOf(row);
    if (index !== -1) {
      this.detallesCompra.splice(index, 1);
      this.readValors()
    } else {
      return
    }
  }


  changeDate(date: string, id: string): void {
    if (id === "load_date") {
      //formatDate(date, 'yyyy-MM-dd', 'en-US')
      this.form.controls.load_date.patchValue(date);
    } else if (id === "end_date") {
      this.form.controls.end_date.patchValue(date);
    }
    this.form.updateValueAndValidity();
  }

  savePayload() {
    this.invalidControls()

    var valid: boolean = true

    if (this.form.invalid) valid = false
    if (this.totalForm.invalid) valid = false

    if (this.detallesCompra.length === 0) {
      this.openSnack("snack-error", "Necesitas una compra en detalles de compra")
      return
    } else {
      this.detallesCompra.forEach(form => {
        if (form.invalid) {

        }
        if (form.invalid) valid = false
      });
    }

    if (!valid) {
      this.openSnack("snack-error", "Revisa los datos de tu formulario")
      return
    }

    var products_services: any[] = []
    this.detallesCompra.forEach(e => {
      var data = e.value

      let iva = 0.16
      let r_iva = 0.0

      if (this.data.action == "sub_oc" || this.data.action == "edit_subOC") {
        iva = this.getIvaValue(e.controls.iva.value)
        r_iva = this.getIvaValue(e.controls.retention_iva.value)
      }

      products_services.push({
        purchase_order_detail_id: data.purchase_order_detail_id ?? null,
        agreed_prize: +data.agreed_prize,
        amount: +data.amount,
        clave_sat: data.clave_sat,
        discount_1: +data.discount_1,
        discount_2: 0,
        ieps: data.ieps_cost ?? 0,
        iva: iva,
        retention_isr: 0,
        retention_iva: r_iva,
        retention_isr_percentage: 0,
        retention_iva_percentage: 0,
        observations: data.observations,
        quantity: +data.quantity,
        product_id: data.product_id ? +data.product_id : null,
        service_id: data.service_id ? +data.service_id : null,
        subtotal: +data.subtotal,
        total: +data.total,
        unit_key: data.unit_key,
      })
    })

    let selected = this.companies.find(company => (company.suppliers.length != 0 || company.transportists.length != 0) && company.id === +this.form.value.supplier_id)

    // console.log("ToSelect", this.form.value.supplier_id)
    // console.log("Selected Company", selected)

    const creation_date = new Date();
    const dateTime = formatDate(creation_date, 'yyyy-MM-dd HH:mm:ss', 'en-US');

    const payload = {
      ...this.form.value,

      load_date: formatDate(this.form.controls.load_date.value, 'yyyy-MM-dd', 'en-US'),
      end_date: formatDate(this.form.controls.end_date.value, 'yyyy-MM-dd', 'en-US'),

      products_services: products_services,

      buyer_id: +this.form.value.buyer_id,
      supplier_id: selected?.id,

      ...this.totalForm.value,

      ieps: this.totalForm.controls.ieps.value,
      iva: this.totalForm.controls.iva.value,

      retention_isr: this.totalForm.controls.retention_isr.value,
      retention_iva: this.totalForm.controls.retention_iva.value,

      transport_type_id: 1,

      creation_date: dateTime,
      ead_date: dateTime,

      load_time: null,
      end_time: null,

      transport_plate: "-",
      transport_type: "-",
      route_price: 0,
      total_price_liter: 0
    }


    if (this.subOrder) {
      payload['main_purchase_order_id'] = this.data.data.id
    }

    if (this.data.action == "edit_subOC") {
      payload['main_purchase_order_id'] = this.data.data.main_purchase_order_id
    }

    // console.log("PayloadDesigned", payload)

    if (this.main) {
      this.salesService.createPurchase(payload).subscribe((r) => {
        this.openSnack("snack-success", r.message)
        this.closeModal()
      }, (e) => {
        this.openSnack("snack-error", e.error.message)
      })
    } else {
      // console.log("UpdateObject", payload)
      this.salesService.updatePurchase(payload, this.dataSource.id).subscribe((r) => {
        this.openSnack("snack-success", r.message)
        this.closeModal()
      }, (e) => {
        this.openSnack("snack-error", e.error.message)
      })
    }
  }

  invalidControls() {
    Object.keys(this.form.controls).forEach(controlName => {
      const control = this.form.controls[controlName];
      if (control.invalid) {
        //console.log(`Control inválido: ${controlName}`);
        control.markAsTouched()
      }
    });

    this.detallesCompra.forEach(purchase => {
      Object.keys(purchase.controls).forEach(controlName => {
        const control = purchase.controls[controlName];
        if (control.invalid) {
          //console.log(`Control inválido: ${controlName}`);
          control.markAsTouched()
        }
      });
    });

    Object.keys(this.totalForm.controls).forEach(controlName => {
      const control = this.totalForm.controls[controlName];
      if (control.invalid) {
        //(`Control inválido: ${controlName}`);
        control.markAsTouched()
      }
    });
  }
}
