import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

// layouts
import { AdminComponent } from "./layouts/admin/admin.component";

// no layouts views
import { IndexComponent } from "./views/index/index.component";

import { CategoriesComponent } from "./components/categories/categories.component";
import { authGuard } from "./guards/auth.guard";
import { VistasComponent } from "./components/vistas/vistas.component";
import { UsersComponent } from "./components/users/users.component";
import { RolesComponent } from "./components/sections/admin/roles/roles.component";
import { RolesViewsComponent } from "./components/sections/admin/roles-views/roles-views.component";
import { TanksComponent } from "./components/tanks/tanks.component";
import { PumpsComponent } from "./components/pumps/pumps.component";
import { ExistenceComponent } from "./components/existence/existence.component";
import { AuthPricesComponent } from "./components/auth-prices/auth-prices.component";
import { PumpMeasureComponent } from "./components/pump-measure/pump-measure.component";
import { ExistenceChangeTypesComponent } from "./components/existence-change-types/existence-change-types.component";
import { ProductsComponent } from "./components/sections/admin/products/products.component";
import { RoutesComponent } from "./components/routes/routes.component";
import { TransportistRoutePriceComponent } from "./components/transportist-route-price/transportist-route-price.component";
import { CommisionProductComponent } from "./components/commision-product/commision-product.component";
import { DiscountProductComponent } from "./components/discount-product/discount-product.component";
import { CompaniesComponent } from "./components/companies/companies.component";
import { TransportistsComponent } from "./components/subCompanies/transportists/transportists.component";
import { ClientsComponent } from "./components/subCompanies/clients/clients.component";
import { SuppliersComponent } from "./components/subCompanies/suppliers/suppliers.component";
import { SalesComponent } from "./components/sections/orders/sales/sales.component";
import { AddressesComponent } from "./components/companies/addresses/addresses.component";
import { PurcaseComponent } from "./components/purcase/purcase.component";
import { ServicesComponent } from "./components/sections/admin/services/services/services.component";
import { LogsListComponent } from "./components/sections/admin/logs/components/logs-list/logs-list.component";
import { CostListComponent } from "./components/sections/costs/components/cost-list/cost-list.component";
import { PurchasesSalesReportComponent } from "./components/sections/reports/components/purchases-sales-report/purchases-sales-report.component";
import { InvoiceClientListComponent } from "./components/sections/invoice/clients/components/invoice-client-list/invoice-client-list.component";
import { InvoiceSuppliersComponent } from "./components/sections/invoice/suppliers/invoice-suppliers/invoice-suppliers.component";
import { FeeClientListComponent } from "./components/sections/invoice/clients/components/fee-client-list/fee-client-list.component";
import { PaymentSuppliersListComponent } from "./components/sections/invoice/suppliers/payment-suppliers-list/payment-suppliers-list.component";
import { DaySalesComponent } from "./components/sections/dashboard/day-sales/day-sales.component";
import { InventoryComponent } from "./components/sections/dashboard/inventory/inventory.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { UnitsInTrancitComponent } from "./components/dashboard/reports/units-in-trancit/units-in-trancit.component";
import { UnitsInPlantComponent } from "./components/dashboard/reports/units-in-plant/units-in-plant.component";
import { SearchOrderComponent } from "./components/sections/orders/search-order/search-order.component";

const routes: Routes = [
  // admin views
  {
    path: "admin",
    component: AdminComponent,
    children: [
      { path: "transportist-route-price", component: TransportistRoutePriceComponent, },
      { path: "commisions-per-product", component: CommisionProductComponent },
      { path: "discount-product", component: DiscountProductComponent },
      { path: "pump-measure", component: PumpMeasureComponent },
      { path: "transportist", component: TransportistsComponent },
      { path: "addressesCompany", component: AddressesComponent },
      { path: "auth-prices", component: AuthPricesComponent },
      { path: "roles-views", component: RolesViewsComponent },
      { path: "categories", component: CategoriesComponent },
      { path: "companies", component: CompaniesComponent },
      { path: "suppliers", component: SuppliersComponent },
      { path: "products", component: ProductsComponent },
      { path: "services", component: ServicesComponent },
      { path: "clients", component: ClientsComponent },
      { path: "routes", component: RoutesComponent },
      { path: "views", component: VistasComponent },
      { path: "users", component: UsersComponent },
      { path: "roles", component: RolesComponent },
      { path: "tanks", component: TanksComponent },
      { path: "pumps", component: PumpsComponent },
      { path: "sales", component: SalesComponent },
      { path: "logs", component: LogsListComponent },
      { path: "invoice-suppliers", component: InvoiceSuppliersComponent },
      { path: "payment-suppliers", component: PaymentSuppliersListComponent },

      // { path: "existence-change-types", component: ExistenceChangeTypesComponent },
      // { path: "existence-types", component: ExistenceComponent },
      {
        /*
          Compras
          fas fa-cart-arrow-down
        */
        path: "purchase-order",
        component: PurcaseComponent
      },
      {
        path: "costs",
        component: CostListComponent,
      },
      {
        /*icon fas fa-book-open*/
        path: "sell-purchase",
        component: PurchasesSalesReportComponent
      },
      {
        path: "clients-invoices",
        component: InvoiceClientListComponent
      },
      {
        path: "clients-fees",
        component: FeeClientListComponent
      },
      {
        path: "report-day-sales",
        component: DaySalesComponent
      },
      {
        path: "report-inventory",
        component: InventoryComponent
      }, {
        path: "dashboard",
        component: DashboardComponent,
      }, {
        path: "units-in-trancit",
        component: UnitsInTrancitComponent
      }, {
        path: "units-in-plant",
        component: UnitsInPlantComponent
      },
      {
        path: 'search-order',
        component: SearchOrderComponent
      }
    ],
    canActivate: [authGuard],
  },

  // no layout views
  {
    path: "",
    component: IndexComponent,
    pathMatch: "full",
    canActivate: [authGuard],
  },
  { path: "**", redirectTo: "", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
