import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ColumnMode, DatatableComponent } from '@swimlane/ngx-datatable';
import { SalesService } from 'app/apiServices/sales.service';
import { Sale } from 'app/models/sale';
import { DataService } from 'app/services/data.service';
import { DocumentsListComponent } from './dialogs/documents-list/documents-list.component';

@Component({
  selector: 'app-search-order',
  templateUrl: './search-order.component.html',
  styleUrls: ['./search-order.component.css']
})
export class SearchOrderComponent implements OnInit {

  @ViewChild('folio') folio: ElementRef;
  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ViewChild("container", { static: true }) container: ElementRef;

  isAuthorizedUser: boolean = false;
  form: FormGroup;
  rows: Sale[] = [];
  temp: Sale[] = [];
  loading: boolean = false;

  selectOptions = {
    selectFilter: true,
    selectNoResultText: "No se encontraron resultados",
    selectSearchPlaceholder: "Buscar...",
  };

  selectCommonClasses = {
    optionsList: "list-none m-0 p-0 w-inherit",
    selectInput:
      "py-2.5 text-base peer block min-h-[auto] w-full rounded rounded-lg border-0 bg-white outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0 cursor-pointer data-[te-input-disabled]:bg-[#e9ecef] data-[te-input-disabled]:cursor-default group-data-[te-was-validated]/validation:mb-4 pr-8",
  };

  ColumnMode = ColumnMode;
  columnMapValues = [
    ["folio", "folio"],
    ["cVendedora", "seller"],
    ["tipoDeVenta", "type_code"],
    ["vendedor", "vendor"],
    ["producto", "product"],
    ["estatus", "order_status"],
    ["creado", "created_at"],
    ["cliente", "client"],
    ["transportista", "transportist"],
  ] as const;
  columnMap: Map<string, string> = new Map(this.columnMapValues);

  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    private salesService: SalesService,
    private dataService: DataService,
    private _snack: MatSnackBar
  ) {
    const user = this.dataService.userValue;
    this.isAuthorizedUser = user.roles[0]?.id === 1 || user.roles[0]?.id === 2;
    this.form = this.fb.group({
      rowsPerPage: [10],
    });
    this.temp = [...this.rows];
  }

  ngOnInit(): void {
  }

  sender() {
    const folioValue = this.folio.nativeElement.value;
    if (folioValue === "") return;

    this.loading = true;

    this.salesService.searchSale(folioValue).subscribe({
      next: (res) => {
        const order: Sale = res.data;
        this.rows = [order];
        this.temp = [...this.rows];
        this.loading = false;
      },
      error: (err) => {
        this.loading = false;
        this._snack.open("Ocurrió un error al buscar la orden", "Cerrar", {
          duration: 3000,
        });
      },
    })
  }

  onSort(event: any): void {
    const sort = event.sorts[0];
    const column = this.columnMap.get(sort.prop);

    if (column) {
      this.temp.sort((a, b) => {
        if (typeof a[column] === "number") {
          if (sort.dir === "asc") return a[column] - b[column];
          else return b[column] - a[column];
        } else if (typeof a[column] === "string") {
          return (
            a[column].localeCompare(b[column]) * (sort.dir === "desc" ? -1 : 1)
          );
        } else if (typeof a[column] === "object") {
          if (column == "seller") {
            return (
              a[column]?.legal_name.localeCompare(b[column]?.legal_name) *
              (sort.dir === "desc" ? -1 : 1)
            );
          }

          if (column == "client" || column == "transportist") {
            return (
              a[column]?.company?.legal_name.localeCompare(
                b[column]?.company?.legal_name
              ) * (sort.dir === "desc" ? -1 : 1)
            );
          }

          if (column == "vendor") {
            const nameA = a[column]?.name;
            const nameB = b[column]?.name;
            const lastNameA = a[column]?.lastname;
            const lastNameB = b[column]?.lastname;
            const fullNameA = `${nameA} ${lastNameA}`;
            const fullNameB = `${nameB} ${lastNameB}`;

            return (
              fullNameA.localeCompare(fullNameB) *
              (sort.dir === "desc" ? -1 : 1)
            );
          }

          if (column == "product" || column == "order_status") {
            return (
              a[column]?.name.localeCompare(b[column]?.name) *
              (sort.dir === "desc" ? -1 : 1)
            );
          }

          if (column == "created_at") {
            if (sort.dir === "asc") {
              return (
                new Date(a[column]).getTime() - new Date(b[column]).getTime()
              );
            } else {
              return (
                new Date(b[column]).getTime() - new Date(a[column]).getTime()
              );
            }
          }
        }
      });

      this.temp = [...this.temp];
    }
  }

  onLimitChange(event: Event): void {
    const limit = (event.target as HTMLSelectElement).value;
    this.changePageLimit(limit);
    this.table.limit = this.form.controls.rowsPerPage.value;
    this.table.recalculate();
    setTimeout(() => {
      if (this.table.bodyComponent.temp.length <= 0) {
        this.table.offset = Math.floor(
          (this.table.rowCount - 1) / this.table.limit
        );
      }
    });
  }

  changePageLimit(limit: any): void {
    this.form.controls.rowsPerPage.setValue(parseInt(limit, 10));
  }

  openDialog(row: Sale): void {
    this.dialog.open(DocumentsListComponent, {
      panelClass: ['dialog-responsive'],
      data: row,
    });
  }

}
