import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Sale } from 'app/models/sale';

@Component({
  selector: 'app-documents-list',
  templateUrl: './documents-list.component.html',
  styleUrls: ['./documents-list.component.css']
})
export class DocumentsListComponent implements OnInit {

  evidence: any[] = [];
  entryStamps: any[] = [];
  sisa: any[] = [];
  exitStamps: any[] = [];
  documentsReady: any[] = [];
  qualityCertificate: any[] = [];
  unity: any[] = [];
  cc: any[] = [];
  tanksReading: any[] = [];

  sale: any = {};

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _dialog: MatDialogRef<DocumentsListComponent>
  ) { }

  ngOnInit(): void {
    this.sale = this.data;

    console.log(this.sale)

    this.getDocuments();
  }

  getFile(url: string) {
    window.open(url, '_blank');
  }

  getDocuments() {
    this.evidence = this.sale.order_attachments.filter((doc: any) => doc.folder === 'Evidence');
    this.entryStamps = this.sale.order_stamp_readings.filter((doc: any) => doc.folder === 'EntryStamps');
    this.sisa = this.sale.order_attachments.filter((doc: any) => doc.folder === 'SISA');
    this.exitStamps = this.sale.order_stamp_readings.filter((doc: any) => doc.folder === 'ExitStamps');
    this.documentsReady = this.sale.order_attachments.filter((doc: any) => doc.folder === 'Documents');
    this.qualityCertificate = this.sale.order_attachments.filter((doc: any) => doc.folder === 'QualityCertificate');
    this.unity = this.sale.order_attachments.filter((doc: any) => doc.folder === 'Unity');
    this.cc = this.sale.order_attachments.filter((doc: any) => doc.folder === 'CC');
    this.tanksReading = this.sale.tank_readings.filter((doc: any) => doc.folder === 'TanksReading');
  }

}
