<div class="container bg-white mx-auto px-4 h-full py-4">
    <div class=" justify-between rounded-t mb-0 px-4 py-3 border-0 pt-6 bg-white">
        <div class=" w-full justify-center">
            <div>
                <h3 class="font-semibold text-lg">
                    Documentos de la Orden
                </h3>
            </div>
        </div>
        <div class="relative w-full mb-3 ">
            <!-- EVIDENCIA -->
            <div class="w-full" *ngIf="evidence.length >0">
                <div class="w-full my-2">
                    <p class="w-full font-semibold">Evidencia</p>
                    <button (click)="getFile(item.url)" *ngFor="let item of evidence"
                        class="text-white p-2 bg-teal-400 font-semibold text-xs my-1 rounded w-full">
                        <i class="fas fa-image" *ngIf="item.extension !== '.pdf'"></i>
                        <i class="fas fa-file" *ngIf="item.extension === '.pdf'"></i>
                        {{item.name}}{{item.extension}}
                    </button>
                </div>
            </div>
            <!-- SISA DE ENTREGA -->
            <div class="w-full" *ngIf="entryStamps.length >0">
                <div class="w-full my-2">
                    <p class="w-full font-semibold">SISA de Entrega</p>
                    <button (click)="getFile(item.url)" *ngFor="let item of entryStamps"
                        class="text-white p-2 bg-teal-400 font-semibold text-xs my-1 rounded w-full">
                        <i class="fas fa-image" *ngIf="item.extension !== '.pdf'"></i>
                        <i class="fas fa-file" *ngIf="item.extension === '.pdf'"></i>
                        {{item.name}}{{item.extension}}
                    </button>
                </div>
            </div>
            <!-- Fotos de SISA de SALIDA -->
            <div class="w-full" *ngIf="sisa.length >0">
                <div class="w-full my-2">
                    <p class="w-full font-semibold">Fotos de SISA de Salida</p>
                    <button (click)="getFile(item.url)" *ngFor="let item of sisa"
                        class="text-white p-2 bg-teal-400 font-semibold text-xs my-1 rounded w-full">
                        <i class="fas fa-image" *ngIf="item.extension !== '.pdf'"></i>
                        <i class="fas fa-file" *ngIf="item.extension === '.pdf'"></i>
                        {{item.name}}{{item.extension}}
                    </button>
                </div>
            </div>
            <!-- SELLOS DE SALIDA -->
            <div class="w-full" *ngIf="exitStamps.length >0">
                <div class="w-full my-2">
                    <p class="w-full font-semibold">Sellos de Salida</p>
                    <button (click)="getFile(item.url)" *ngFor="let item of exitStamps"
                        class="text-white p-2 bg-teal-400 font-semibold text-xs my-1 rounded w-full">
                        <i class="fas fa-image" *ngIf="item.extension !== '.pdf'"></i>
                        <i class="fas fa-file" *ngIf="item.extension === '.pdf'"></i>
                        {{item.name}}{{item.extension}}
                    </button>
                </div>
            </div>
            <!-- DOCUMENTACION LISTA -->
            <div class="w-full" *ngIf="documentsReady.length >0">
                <div class="w-full my-2">
                    <p class="w-full font-semibold">Documentacion Lista</p>
                    <button (click)="getFile(item.url)" *ngFor="let item of documentsReady"
                        class="text-white p-2 bg-teal-400 font-semibold text-xs my-1 rounded w-full">
                        <i class="fas fa-image" *ngIf="item.extension !== '.pdf'"></i>
                        <i class="fas fa-file" *ngIf="item.extension === '.pdf'"></i>
                        {{item.name}}{{item.extension}}
                    </button>
                </div>
            </div>
            <!-- CERTIFICADO DE CALIDAD -->
            <div class="w-full" *ngIf="qualityCertificate.length >0">
                <div class="w-full my-2">
                    <p class="w-full font-semibold">Certificado de Calidad</p>
                    <button (click)="getFile(item.url)" *ngFor="let item of qualityCertificate"
                        class="text-white p-2 bg-teal-400 font-semibold text-xs my-1 rounded w-full">
                        <i class="fas fa-image" *ngIf="item.extension !== '.pdf'"></i>
                        <i class="fas fa-file" *ngIf="item.extension === '.pdf'"></i>
                        {{item.name}}{{item.extension}}
                    </button>
                </div>
            </div>
            <!-- FOTO DE UNIDAD -->
            <div class="w-full" *ngIf="unity.length >0">
                <div class="w-full my-2">
                    <p class="w-full font-semibold">Fotos de la Unidad</p>
                    <button (click)="getFile(item.url)" *ngFor="let item of unity"
                        class="text-white p-2 bg-teal-400 font-semibold text-xs my-1 rounded w-full">
                        <i class="fas fa-image" *ngIf="item.extension !== '.pdf'"></i>
                        <i class="fas fa-file" *ngIf="item.extension === '.pdf'"></i>
                        {{item.name}}{{item.extension}}
                    </button>
                </div>
            </div>
            <!-- SISA DE CC -->
            <div class="w-full" *ngIf="cc.length >0">
                <div class="w-full my-2">
                    <p class="w-full font-semibold">SISA de CC (Certificado de Carga)</p>
                    <button (click)="getFile(item.url)" *ngFor="let item of cc"
                        class="text-white p-2 bg-teal-400 font-semibold text-xs my-1 rounded w-full">
                        <i class="fas fa-image" *ngIf="item.extension !== '.pdf'"></i>
                        <i class="fas fa-file" *ngIf="item.extension === '.pdf'"></i>
                        {{item.name}}{{item.extension}}
                    </button>
                </div>
            </div>
            <!-- LITROS CARGADOS -->
            <div class="w-full" *ngIf="tanksReading.length >0">
                <div class="w-full my-2">
                    <p class="w-full font-semibold">Litros Cargados</p>
                    <button (click)="getFile(item.url)" *ngFor="let item of tanksReading"
                        class="text-white p-2 bg-teal-400 font-semibold text-xs my-1 rounded w-full">
                        <i class="fas fa-image" *ngIf="item.extension !== '.pdf'"></i>
                        <i class="fas fa-file" *ngIf="item.extension === '.pdf'"></i>
                        {{item.name}}{{item.extension}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>