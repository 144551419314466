import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ColumnMode, DatatableComponent } from "@swimlane/ngx-datatable";
import { FormComponent } from "./dialogs/form/form.component";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Sale } from "app/models/sale";
import { LabOrderComponent } from "../lab-order/lab-order.component";
import { Collapse, Dropdown, Select, initTE } from "tw-elements";
import { SalesService } from "app/apiServices/sales.service";
import { SaleDetailComponent } from "./dialogs/sale-detail/sale-detail.component";
import { NotificationsComponent } from "app/components/alerts/notifications/notifications.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { LabOrder } from "app/models/labOrder";
import { DateTime } from "luxon";
import { AlertComponent } from "app/components/alerts/alert/alert.component";
import { DataService } from "app/services/data.service";
import { DateBody } from "app/interfaces/date.interface";
import { OrderStatus } from "app/models/orderStatus";
import { InOrderStatusRes } from "app/interfaces/order.status";
import { SaleEvidencesComponent } from "./dialogs/sale-evidences/sale-evidences.component";
declare let DateRangePicker: any;

enum OrderStatusColums {
  CREADA = 'PEDIDOS POR LIBERAR',
  ENPLANTA = 'PRIORIDAD DE CARGA',
  CARGA = 'CARGA',
  DESCARGAERRORLABORATORIO = 'DESCARGA (ERROR LABORATORIO)',
  DOCUMENTACIÓN = 'DOCUMENTACIÓN',
  Returned = 'TRANSITO',
  DESCARGA = 'DESCARGA',
  ENTREGA = 'ENTREGA',
  PROVISIONES = 'PROVISIONES',
}

@Component({
  selector: "app-sales",
  templateUrl: "./sales.component.html",
  styleUrls: ["./sales.component.css"],
})
export class SalesComponent implements OnInit {
  @ViewChild("statusId", { static: true })
  orderStatusSelect: ElementRef;
  rows: Sale[] = [];
  temp: Sale[] = [];
  form: FormGroup;
  ColumnMode = ColumnMode;
  columnMapValues = [
    ["folio", "folio"],
    ["cVendedora", "seller"],
    ["tipoDeVenta", "type_code"],
    ["vendedor", "vendor"],
    ["producto", "product"],
    ["estatus", "order_status"],
    ["creado", "created_at"],
    ["cliente", "client"],
    ["transportista", "transportist"],
  ] as const;
  columnMap: Map<string, string> = new Map(this.columnMapValues);
  loading: boolean = false;
  isAuthorizedUser: boolean = false;
  status: any[] = [];
  currentStatus: number = undefined;

  selectOptions = {
    selectFilter: true,
    selectNoResultText: "No se encontraron resultados",
    selectSearchPlaceholder: "Buscar...",
  };

  selectCommonClasses = {
    optionsList: "list-none m-0 p-0 w-inherit",
    selectInput:
      "py-2.5 text-base peer block min-h-[auto] w-full rounded rounded-lg border-0 bg-white outline-none transition-all duration-200 ease-linear focus:placeholder:opacity-100 data-[te-input-state-active]:placeholder:opacity-100 motion-reduce:transition-none [&:not([data-te-input-placeholder-active])]:placeholder:opacity-0 cursor-pointer data-[te-input-disabled]:bg-[#e9ecef] data-[te-input-disabled]:cursor-default group-data-[te-was-validated]/validation:mb-4 pr-8",
  };

  @ViewChild(DatatableComponent) table: DatatableComponent;
  @ViewChild("container", { static: true }) container: ElementRef;
  dateRangePicker: any;

  constructor(
    public dialog: MatDialog,
    private fb: FormBuilder,
    private salesService: SalesService,
    private dataService: DataService,
    private _snack: MatSnackBar
  ) {
    const user = this.dataService.userValue;
    this.isAuthorizedUser = user.roles[0]?.id === 1 || user.roles[0]?.id === 2;
    this.form = this.fb.group({
      rowsPerPage: [10],
    });
    this.temp = [...this.rows];
    this.getOrderStatuses();
  }

  ngOnInit(): void {
    initTE({ Collapse, Dropdown });
    new Select(
      this.orderStatusSelect.nativeElement,
      this.selectOptions,
      this.selectCommonClasses
    );

    const dateRangePickerEl = document.getElementById("dateRangePickerId");
    this.dateRangePicker = new DateRangePicker(dateRangePickerEl, {
      allowOneSidedRange: true,
      language: "es",
      format: "yyyy-mm-dd",
      orientation: "bottom",
    });
    this.setInitialDate();
  }

  sender() {
    this.loading = true;
    const [startDate, endDate] = this.dateRangePicker.getDates();
    const dateBody: DateBody = {
      start_date: DateTime.fromJSDate(startDate).toFormat("yyyy-MM-dd"),
      end_date: DateTime.fromJSDate(endDate).toFormat("yyyy-MM-dd"),
    };

    this.getFilteredSales(
      dateBody,
      this.currentStatus,
      this.currentStatus == 0
    );
  }

  getFilteredSales(
    dateBody: DateBody,
    statusId: number = undefined,
    deactivates: boolean = false
  ) {
    this.salesService
      .getSales(dateBody.start_date, dateBody.end_date, statusId, deactivates)
      .subscribe(
        (r) => {
          this.loading = false;
          if (r.status === "Éxito") {
            if (deactivates) {
              this.rows = r.data.filter((sale) => {
                return !sale.active && sale?.order_status?.id >= 8;
              });
            } else {
              this.rows = r.data;
            }

            this.temp = [...this.rows];
          }
        },
        (e) => {
          this.loading = false;
        }
      );
  }

  getOrderStatuses(): void {
    this.salesService.getSalesTypes().subscribe((res: InOrderStatusRes) => {
      if (res.status === "Éxito") {
        this.status = res.data.map((status) => {
          const formattedName = this.formatStatusName(status.name);
          return {
            ...status,
            tmpName: OrderStatusColums[formattedName as keyof typeof OrderStatusColums] || 'Desconocido'
          };
        });
      }
    });
  }

  formatStatusName(statusName: string): string {
    return statusName
      .replace(/\s+/g, '') // Elimina espacios
      .replace(/[()]/g, '') // Elimina paréntesis
      .normalize('NFD').replace(/[\u0300-\u036f]/g, ''); // Elimina acentos
  }

  changeDate(event: any) {
    const [startDate, endDate] = this.dateRangePicker.getDates();

    if (startDate && endDate) {
      this.dateRangePicker.datepickers[0].element?.click();
      this.container?.nativeElement?.click();
      this.dateRangePicker.datepickers[1].element?.click();
      this.container?.nativeElement?.click();
      const formattedStartDate =
        DateTime.fromJSDate(startDate).toFormat("yyyy-MM-dd");
      const formattedEndDate =
        DateTime.fromJSDate(endDate).toFormat("yyyy-MM-dd");
    }
  }

  setInitialDate() {
    this.dateRangePicker.setDates(
      DateTime.local().minus({ week: 1 }).toISODate(),
      DateTime.local().toISODate()
    );
  }

  onLimitChange(event: Event): void {
    const limit = (event.target as HTMLSelectElement).value;
    this.changePageLimit(limit);
    this.table.limit = this.form.controls.rowsPerPage.value;
    this.table.recalculate();
    setTimeout(() => {
      if (this.table.bodyComponent.temp.length <= 0) {
        this.table.offset = Math.floor(
          (this.table.rowCount - 1) / this.table.limit
        );
      }
    });
  }

  changePageLimit(limit: any): void {
    this.form.controls.rowsPerPage.setValue(parseInt(limit, 10));
  }

  onChangeStatus(statusId: number): void {
    const status = Number(statusId);
    this.currentStatus = status;
  }

  onSort(event: any): void {
    const sort = event.sorts[0];
    const column = this.columnMap.get(sort.prop);

    if (column) {
      this.temp.sort((a, b) => {
        if (typeof a[column] === "number") {
          if (sort.dir === "asc") return a[column] - b[column];
          else return b[column] - a[column];
        } else if (typeof a[column] === "string") {
          return (
            a[column].localeCompare(b[column]) * (sort.dir === "desc" ? -1 : 1)
          );
        } else if (typeof a[column] === "object") {
          if (column == "seller") {
            return (
              a[column]?.legal_name.localeCompare(b[column]?.legal_name) *
              (sort.dir === "desc" ? -1 : 1)
            );
          }

          if (column == "client" || column == "transportist") {
            return (
              a[column]?.company?.legal_name.localeCompare(
                b[column]?.company?.legal_name
              ) * (sort.dir === "desc" ? -1 : 1)
            );
          }

          if (column == "vendor") {
            const nameA = a[column]?.name;
            const nameB = b[column]?.name;
            const lastNameA = a[column]?.lastname;
            const lastNameB = b[column]?.lastname;
            const fullNameA = `${nameA} ${lastNameA}`;
            const fullNameB = `${nameB} ${lastNameB}`;

            return (
              fullNameA.localeCompare(fullNameB) *
              (sort.dir === "desc" ? -1 : 1)
            );
          }

          if (column == "product" || column == "order_status") {
            return (
              a[column]?.name.localeCompare(b[column]?.name) *
              (sort.dir === "desc" ? -1 : 1)
            );
          }

          if (column == "created_at") {
            if (sort.dir === "asc") {
              return (
                new Date(a[column]).getTime() - new Date(b[column]).getTime()
              );
            } else {
              return (
                new Date(b[column]).getTime() - new Date(a[column]).getTime()
              );
            }
          }
        }
      });

      this.temp = [...this.temp];
    }
  }

  updateFilter(event: Event) {
    const val: string = (event.target as HTMLInputElement).value.toLowerCase();
    const temp: Sale[] = this.rows.filter((d: Sale) => {
      return d.folio.toLowerCase().indexOf(val) !== -1 || !val;
    });

    this.temp = temp;
    this.table.offset = 0;
  }

  getSales(): void {
    this.loading = true;
    this.salesService.getSales().subscribe({
      next: (res) => {
        this.loading = false;
        if (res.status === "Éxito") {
          this.rows = res.data.filter((sale) => {
            return sale.active;
          });
          this.temp = [...this.rows];
        }
      },
      error: (e) => {
        this.loading = false;
      },
    });
  }

  compareLuxonDates(a: DateTime, b: DateTime) {
    return a.toMillis() - b.toMillis();
  }

  openDialog(data: Sale, action: string = "store") {
    const dialogRef = this.dialog.open(FormComponent, {
      width: "1280px",
      height: "750px",
      maxWidth: "1280px",
      data: {
        action,
        data,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.status && result.status == 200) {
        this.sender();
      }
    });
  }

  confirmDelete(id: number): void {
    const dialogRef = this.dialog.open(AlertComponent, {
      data: {
        body: "¿Deseas eliminar esta venta?",
        showBtnClose: true,
        isConfirm: true,
      },
    });
    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) this.delete(id);
    });
  }

  delete(id: number): void {
    this.salesService.deleteSale(id).subscribe({
      next: (res) => {
        if (res.status === "Éxito") {
          this.sender();
          this.openSnack("snack-success", "Venta eliminado");
        }
      },
      error: (e) => {
        this.openSnack(
          "snack-error",
          "Ha ocurrido un error al eliminar la venta"
        );
      },
    });
  }

  addOrderLab(data: Sale) {
    const labOrder =
      data.lab_orders && data.lab_orders.length > 0
        ? data.lab_orders.find((labOrder: LabOrder) => labOrder.active)
        : undefined;

    const dialogRef = this.dialog.open(LabOrderComponent, {
      width: "84vw",
      maxWidth: "84vw",
      maxHeight: "750px",
      disableClose: true,
      hasBackdrop: true,
      autoFocus: false,
      data: {
        labOrder,
        order: data,
        edit: labOrder != null,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.status && result.status == 200) {
        this.sender();
      }
    });
  }

  openOrderEvidencesDialog(data: Sale) {
    const dialogRef = this.dialog.open(SaleEvidencesComponent, {
      width: "84vw",
      maxWidth: "84vw",
      maxHeight: "750px",
      data: {
        attachments: data.order_attachments,
      },
    });
  }

  openDetailDialog(data: Sale, action: string = "store") {
    const dialogRef = this.dialog.open(SaleDetailComponent, {
      data: {
        data,
        action,
      },
      maxHeight: "750px",
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.status && result.status == 200) {
        this.sender();
      }
    });
  }

  openSnack(color: string, message: string): void {
    this._snack.openFromComponent(NotificationsComponent, {
      panelClass: [color],
      data: { message },
      duration: 3000,
      verticalPosition: "top",
    });
  }
}
